<template>
  <div class="error-page">
    <div class="error-container">
      <h1 class="error-container__title">404</h1>
      <p class="error-container__description">
        The page you looking for not exist!
      </p>
      <button class="error-container__btn" @click="goToDiscover()">
        Go back
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToDiscover() {
      this.$router.push("/discover");
    },
  },
};
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 0 4rem;
  .error-container {
    width: 60rem;
    border-radius: 1rem;
    padding: 5rem 4rem;
    margin-top: 10vh;
    height: fit-content;
    &__title {
      font-size: 15rem;
      line-height: normal;
      font-family: $font-primary-bold;
      margin-bottom: 2rem;
      text-align: center;
      color: $color-black;
    }
    &__description {
      font-size: 2.5rem;
      line-height: normal;
      font-family: $font-primary;
      margin-bottom: 2rem;
      text-align: center;
      color: $color-black;
    }
    &__btn {
      font-size: 2.2rem;
      font-family: $font-primary-medium;
      width: 17rem;
      margin: 4rem auto 0;
      height: 5.5rem;
      border: none;
      outline: none;
      background-color: $color-primary;
      color: $color-white;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
